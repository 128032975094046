import * as React from "react";
import { CreateUser, EditUser, UserList} from "./users"
import { CreateHealthSherpaKey, HealthSherpaKeysList } from "./helthsherpa-keys"

import { Admin, Resource, ListGuesser, fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import authProvider from "./authProvider";
import { v4 as uuidv4 } from 'uuid';

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const { access_token } = JSON.parse(localStorage.getItem('auth') ?
    localStorage.getItem('auth') : { 'access_token': null });
    options.headers.set('Authorization', `Bearer ${access_token}`);
    
    let queryStringStated = false;
    let newUrl = "";
    for( let f of url) {
      if( f == "?" && !queryStringStated) {
        queryStringStated = true;
        newUrl += f;
      } else if ( f !="?") {
        newUrl +=f;
      } else {
        newUrl +="&"
      }
    }
    
    return fetchUtils.fetchJson(newUrl, options);

};

const dataProvider = simpleRestProvider('https://api.internal.qa.apolloquotes.com/v1/admin',httpClient);

const App = () => (
  <Admin authProvider={authProvider} dataProvider= { dataProvider } >
    <Resource list={ UserList } create={CreateUser} edit={EditUser } name="users"  >
      </Resource>
    <Resource list={HealthSherpaKeysList} create={CreateHealthSherpaKey}  name="health-sherpa-agent-keys"  >
    </Resource>
    <Resource name="marketplace-carriers"/>
  </Admin >
);

export default App;